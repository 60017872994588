import React, { useState } from "react";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";
// import CategoryItem from "../components/categoryItem";

import {
  Breadcrumbs,
  Button,
  // FlipCard,
  Hero,
  IconBox,
  // SecondaryHeader,
  SectionHeader,
  Link,
  SecondaryHeader,
  FlipCard,
} from "../components/common";
import Item from "../components/item";
// import CategoryItem from "../components/categoryItem";
// import CollectionItem from "../components/collectionItem";

// import Discussion from "../assets/images/vectors/discussion.svg";
// import QuestionMark from "../assets/images/vectors/question-mark.svg";
import Subscription from "../components/subscription";
import { generateHeading } from "../helpers";
import CollectionItem from "../components/collectionItem";

function CategoryPage({
  pageContext: { data: category, collections },
  location,
}) {
  if (!category) {
    return null;
  }

  const [itemCount, setItemCount] = useState(15);
  const products = category.products.slice(0, itemCount);
  const title = generateHeading(category.Headline, category?.products?.length);

  return (
    <Layout>
      <SEO
        keywords={[`vackground`, `pattern`, `background`, `design`]}
        title={category?.SEOTitle || title}
        description={category?.MetaDescription || category?.SubHeadline}
        image={
          category?.products?.[0]?.FeaturedImage?.localFile?.childImageSharp
            ?.gatsbyImageData?.images?.fallback?.src
        }
        metaKeywords={category?.MetaKeywords}
        metaRobots={category?.MetaRobots}
        path={category?.CanonicalURL || location?.pathname}
      />

      <section className="dev-vg-hero-section md:mt-24 my-12 mb-18 md:mb-32">
        <Hero
          subTitle={`${
            category.Eyebrow ||
            `Everything ${category.Headline || "Background"}`
          }`}
          title={title}
          description={category?.SubHeadline}
        >
          <Breadcrumbs
            lists={[
              {
                title: "Home",
                to: "/",
              },
              {
                title: "All Products",
                to: "/categories",
              },
              {
                title: category?.Name || "Backgrounds",
                to: `/category/${category?.URL || ""}`,
              },
            ]}
          />
        </Hero>
      </section>
      <section className="md:mb-32 mb-24">
        {/* <div className="flex items-center justify-between mb-8">
          <div>Show Sale Products Only</div>
          <button className="flex-inline items-center">
            View All <i className="vg-arrow-right ml-2" />
          </button>
        </div> */}
        <div className="dev-vg-g-product-section__items grid lg:grid-cols-3 md:grid-cols-2 grid-cols-2 md:gap-x-8 gap-x-6 md:gap-y-16 gap-y-8">
          {products?.map((product) => (
            <Item
              key={product.id}
              title={product.Name}
              price={product.Price}
              salePrice={product.SalePrice}
              featuredImage={product.FeaturedImage.localFile}
              url={product.URL}
              fromCategory={{
                Name: category.Name,
                URL: category.URL,
              }}
            />
          ))}
        </div>
        {category.products.length > itemCount && (
          <div className="md:mt-16 mt-12 flex items-center justify-center">
            <Button
              onClick={() => setItemCount(itemCount + 15)}
              text="Load More Products"
              icon="vg-chevron-down"
            />
          </div>
        )}
      </section>
      {/* {subCategories.length && (
        <section className="mb-32">
          <div className="mb-12">
            <SecondaryHeader
              title="Sub Categories"
              description="Categories have too many products to choose from. That’s why we gathered our design assets into niche collections. Go grab your desired asset now, it’s easier than ever."
            />
          </div>
          <div className="grid grid-cols-3 gap-x-8 gap-y-16">
            <CategoryItem />
            <CategoryItem />
            <CategoryItem />
          </div>
        </section>
      )} */}
      <section className="mb-32">
        <div className="md:mb-16 mb-20">
          <SectionHeader
            art
            title={
              <>
                State of The Art Backgrounds
                <br />
                Comes With Benefits
              </>
            }
            description="We aim to give more, to ensure you grow big. Get the best perks for your best money. Use our products unlimited times in unlimited projects by unlimited ways."
          />
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:gap-8 gap-12">
          <IconBox icon="vg-infinity" title="Free Trial Version">
            Use the free version to try before buy or consider it as a freebie!
          </IconBox>
          <IconBox icon="vg-file" title="Unlimited Use">
            Buy once and use it forever. Sounds like A lifetime deal? Yes!
          </IconBox>
          <IconBox
            className="col-span-1 md:col-span-2 lg:col-span-1"
            icon="vg-circle-check"
            title="60-Day Refund"
          >
            <>
              Never ever worry while you checkout. Get a piece of mind, for{" "}
              <Link to="/refund">60 days!</Link>
            </>
          </IconBox>
        </div>
      </section>
      {/* <section className="mb-40">
        <div className="mb-16">
          <SectionHeader
            art
            artVector={Discussion}
            title={
              <>
                Read The Reactions!
                <br />
                Our Happy Customers Shoutout
              </>
            }
          />
        </div>
        <div className="flex items-center justify-center gap-x-8">Tweets</div>
      </section> */}
      {category?.OptionalHeadline && category?.OptionalDescription && (
        <section className="mb-40">
          <div>
            <SecondaryHeader
              title={category.OptionalHeadline}
              description={category.OptionalDescription}
            />
          </div>
        </section>
      )}
      {collections?.length && (
        <section className="mb-32">
          <div className="mb-12">
            <SectionHeader
              title="Curated Collections"
              description="Categories have too many products to choose from. That’s why we gathered our design assets into niche collections. Go grab your desired asset now, it’s easier than ever."
            />
          </div>
          <div className="dev-vg-g-product-section__items grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-16">
            {collections.map((collection, index) => (
              <CollectionItem key={index} collection={collection} />
            ))}
          </div>
          <div className="mt-16 flex items-center justify-center">
            <Button
              icon="vg-arrow-right"
              text="Explore Collections"
              to="/collections"
            />
          </div>
        </section>
      )}
      {!!category.FAQs?.data?.length &&
        category.FAQs?.data?.map((item) => (
          <section log={console.log({ item })} className="mb-40" key={item.id}>
            <div>
              <SecondaryHeader title={item.attributes.FAQType} />
            </div>
            <div className="max-w-3xl m-auto">
              {item.attributes.Answers.map((answer) => (
                <FlipCard key={answer.id} title={answer.Title}>
                  {answer.Description}
                </FlipCard>
              ))}
            </div>
          </section>
        ))}
      {/* <section className="mb-40">
        <div className="mb-16">
          <SectionHeader art artVector={QuestionMark} title="Background FAQs" />
        </div>
        <div className="px-48">
          <FlipCard title="What kind of graphic resources you make regularly?">
            Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a
          </FlipCard>
          <FlipCard title="Do your surface patterns can be repeatable (tile-able)?">
            Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a
          </FlipCard>
          <FlipCard title="Do your files backward Adobe software compatible?">
            Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a
          </FlipCard>
          <FlipCard title="How to edit vector files?">
            Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a
          </FlipCard>
          <FlipCard title="Can I use the design files in unlimited projects?">
            Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a
          </FlipCard>
          <FlipCard title="Can I use the free files in my design project for commercial print?">
            Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a
          </FlipCard>
          <FlipCard title="Can I use Apple Pay or Google Wallet?">
            Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a
          </FlipCard>
          <FlipCard title="I need a product right now ASAP, but would like to pay later, what should I do?">
            Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a
          </FlipCard>
          <FlipCard title="If I face any difficulties to work with the files, would you help?">
            Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a
          </FlipCard>
          <FlipCard title="I have a question to ask, how can I contact?">
            Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a
          </FlipCard>
        </div>
      </section> */}
      <section className="lg:mb-16 mb-8 mt-48">
        <Subscription />
      </section>
    </Layout>
  );
}

CategoryPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default CategoryPage;
